.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.backdroplanding {
  background-color: black;
	height: 100vh;
}

.EsteeLauderCard{
    content: "";
    position: fixed;
    background: grey url(../src/Images/Estee.jpg) center center no-repeat;
    background-size: cover;
    opacity: 1;
    height: 100vh;
    z-index: -1;
    min-height: 100vh;
    background-repeat: "no-repeat";
    border-color: "white";
    right: 0;
    top: 0;
    left: 0;
}

.background {
	background: linear-gradient(-60deg,#460C88, #177fcf, #04A558, #0050A3, #024F6E, #BE2130);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
	height: 100vh;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
